




























import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    events: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props, { root, emit }) {
    const model = reactive({
      value: [...props.value] as any,
      search: "",
    });

    const items = ref<any[]>([...props.events]);

    const state = reactive({
      loading: false,
      items: [],
    });

    const fetchEvents = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .get("event")
        .then(({ data: { events } }: any) => {
          state.items = events.map((e: any) => ({
            id: e.id,
            name: e.name,
            siemensPrefix: e.siemensPrefix,
          }));
        })
        .catch((error: any) => console.log(error))
        .finally(() => (state.loading = false));
    };
    onMounted(fetchEvents);

    const search = (value: string) => {
      if (state.loading) return;

      if (value) {
        const axiosInstance = root.$store.getters[
          "api/getInstance"
        ] as AxiosInstance;

        state.loading = true;

        axiosInstance
          .get("event")
          .then(({ data: { events } }: any) => {
            const ids = new Set(items.value.map((i) => i.id));
            items.value = [
              ...items.value,
              ...events.filter((e: any) => !ids.has(e.id)),
            ];
          })
          .catch((error: any) => console.log(error))
          .finally(() => (state.loading = false));
      }
    };

    watch(() => model.search, search);

    const filter = (item: any, q: string) =>
      item.name?.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1;

    const remove = (item: any) =>
      (model.value = model.value.filter((i: any) => i.id !== item.id));

    watch(
      () => model.value,
      (value) => emit("input", value)
    );

    return { model, state, props, filter, remove };
  },
});
